import "./jquery";
import "jquery-ujs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "font-awesome";
// import "jquery.fancybox.min";

import "../assets/javascripts/catalogue";
import "../assets/javascripts/compare";
import "../assets/javascripts/old-basket";
import "../assets/javascripts/navigation";
import "../../vendor/assets/javascripts/jquery.fancybox.min";
import "../../vendor/assets/stylesheets/jquery.fancybox.min.css";
import "../../vendor/assets/javascripts/slick.min";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "@fortawesome/fontawesome-free/css/brands.css";

import "../assets/stylesheets/application.scss";

$(function () {
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 0) {
      $("#page_scroll").addClass("scrolled");
    } else {
      $("#page_scroll").removeClass("scrolled");
    }
  });
  $("#page_scroll").on("click", function (e) {
    e.stopPropagation();
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 1000);
  });

  // $(window).on('scroll', function() {
  //   if ($(window).scrollTop() > 0) {
  //     $('#page_scroll').addClass('scrolled');
  //   } else {
  //     $('#page_scroll').removeClass('scrolled');
  //   }
  // })
  // $('#page_scroll').on('click', function(e) {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   $("html, body").animate({ scrollTop: 0 }, 1000);
  // });

  // $(window).on('click', function() {
  // 	if ($(window).width() < 992) {
  // 		$('.page_nav--menu').removeClass('open');
  // 	}
  // })
  // $('.page_nav--menu').on('click', function(e) {
  // 	if ($(window).width() < 992) {
  // 		e.stopPropagation();
  // 	}
  // })

  $(
    ".form-check-label > input[type=checkbox], .radio_input > input, .checkbox_input > input[type=checkbox]"
  ).each(function () {
    $(this).after('<span class="radio_input_hack">');
    $(this).parent().addClass("has_hack");
  });
  
  

  $(".products-carousel.slick-carousel").slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $(document).on("click", ".home-fragment-5 .pagination a", function () {
    var src = $(this);
    var box = $(this).parents(".home-fragment-5");
    $.get(src.prop("href"), function (response) {
      box.replaceWith(response);
    });
    return false;
  });
});
