document.addEventListener('DOMContentLoaded', function() {
	document.querySelector(".headerNav .headerNav-menuButton").addEventListener("click", function () {
		document.querySelector(".headerNav .headerNav-menu").classList.toggle("open");
	});

	document.querySelector(".headerNav .headerNav-menuOverlay").addEventListener("click", function () {
		document.querySelector(".headerNav .headerNav-menu").classList.remove("open");
	});

	document.querySelectorAll(".headerNav .headerNav-navigation > li > a .headerNav-menuArrow").forEach(function (arrow) {
		arrow.addEventListener("click", function (e) {
			e.preventDefault();
			e.stopPropagation();
			const menuContainer = this.closest('li');
			if (menuContainer.classList.contains("open")) {
				menuContainer.classList.remove("open");
				$(menuContainer).find(".headerNav-submenu").slideUp(300);
			} else {
				$('.headerNav .headerNav-submenu').slideUp(300);
				$('.headerNav .headerNav-navigation > li').removeClass("open");
				menuContainer.classList.add("open");
				$(menuContainer).find(".headerNav-submenu").slideDown(300);
			}
		});
	});
	const menuClose = document.querySelector(".headerNav .headerNav-menuClose");
	menuClose.addEventListener("click", function (e) {
		e.preventDefault();
		e.stopPropagation();
		document.querySelector(".headerNav .headerNav-menu").classList.remove("open");
	});
});

document.addEventListener('DOMContentLoaded', function() {
  const searchButton = document.querySelector('.headerNav-search');
  const searchFormContainer = document.querySelector('.headerNav-searchFormContainer');


	if (searchButton != null && searchFormContainer != null) {
		searchButton.addEventListener('click', function(e) {
			e.preventDefault();
			e.stopPropagation();
			searchFormContainer.classList.toggle('open');
		});
	
		// Close search form when clicking outside
		document.addEventListener('click', function(e) {
			if (!searchButton.contains(e.target) && !searchFormContainer.contains(e.target)) {
				searchFormContainer.classList.remove('open');
			}
		});
	}
});

